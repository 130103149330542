@font-face {
  font-family: Supermarket;
  src: url('assets/fonts/supermarket.ttf') format('truetype');
}

@font-face {
  font-family: CSPrajad;
  src: url('assets/fonts/CSPraJad.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: CSPrajad;
  src: url('assets/fonts/CSPraJad-Italic.otf') format('opentype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: CSPrajad;
  src: url('assets/fonts/CSPraJad-bold.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: CSPrajad;
  src: url('assets/fonts/CSPraJad-boldItalic.otf') format('opentype');
  font-weight: bold;
  font-style: italic;
}

body {
  margin: 0;
  height: 100vh;
}

body,
button,
.button {
  font-family: 'Supermarket', 'CSPrajad', -apple-system, BlinkMacSystemFont,
    'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input,
select,
textarea,
.__content {
  font-family: 'CSPrajad', 'Supermarket' !important;
}

@media screen and (min-width: 1088px) {
  .container {
    max-width: 1148px !important;
    width: 1148px !important;
  }
}

.custom-scroll::-webkit-scrollbar-track {
  /* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); */
  border-radius: 10px;
  background-color: #f5f5f5;
}

.custom-scroll::-webkit-scrollbar {
  width: 12px;
  background-color: #f5f5f5;
}

.custom-scroll::-webkit-scrollbar-thumb {
  border-radius: 10px;
  /* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3); */
  background-color: #aaaa;
}

#root {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.card {
  border-radius: 8px;
  box-shadow: 0 10px 8px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0);
}

.card-content {
  padding: 1rem;
}

.card img {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.card-image > .fa {
  font-size: 8rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  color: #209cee;
}

.__app-body {
  /* display: flex;
  flex-direction: column; */
}

.__editor-box {
  border: 1px solid;
  border-color: lightgray;
  background: #fafafa3b;
  border-radius: 16px;

  z-index: 8;
  padding: 16px;
  /* margin-left: 1em;
  margin-right: 1em;*/
  margin-bottom: 8px;
  min-height: 120px;
  cursor: pointer;
  position: relative;
}

.__editor-box > .icon {
  position: absolute;
  color: gray;
  top: 8px;
  right: 8px;
}

.__highlight-editor-box > .icon :hover {
  color: black !important;
  border: 1px solid;
  /* border-color: white; */
  opacity: 0.8;
  transition: 300ms;
}

.__highlight-editor-box:hover {
  border-color: black !important;
  color: black;
  background: rgba(128, 128, 128, 0.3);
  opacity: 0.8;
  transition: 300ms;
}

.__highlight-editor-box:hover > .icon {
  color: black;
  opacity: 0.8;
  transition: 300ms;
}

.__highlight-editor :hover {
  color: black !important;
  opacity: 0.8;
  transition: 300ms;
}

.__has-navbar {
  margin-top: 3.5rem !important;
}
